import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {
    getOrderAction,
    listTablesAction,
    updateOrderAction,
    getReceiptViewAction
} from "../../redux/order/actions";
import {useParams} from "react-router-dom";
import {listMenuAction} from "../../redux/menu/actions";
import AddNewItemModal from "./components/AddItemModal";
import UpdateItemModal from "./components/UpdateItemModal";
import BottomCallout from "../../components/BottomCallout";
import {FaEdit} from 'react-icons/fa';
import ConfirmationModal from "../../components/ConfirmationModal";
import ReceiptModal from './components/ReceiptModal';
import {getReceiptViewReducer} from "../../redux/order/reducers";
import {BASE_URL} from '../../config';

const OrderPage = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState(null);
    const [status, setStatus] = useState('');
    const [updateTable, setUpdateTable] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)
    const [itemStatuses, setItemStatuses] = useState({});
    const [orderItemPendingUpdate, setOrderItemPendingUpdate] = useState(null);
    const [trigger, setTrigger] = useState(0);

    const dispatch = useDispatch();
    const getOrderReducer = useSelector(state => state.getOrderReducer.order);
    const menuList = useSelector(state => state.listMenuReducer.menu);
    const {error, loading, success} = useSelector(state => state.updateOrderReducer);
    const tableList = useSelector(state => state.listTablesReducer.tables);
    const receipt = useSelector(state => state.getReceiptReducer.receipt);
    const params = useParams();

    useEffect(() => {
        dispatch(getOrderAction(params.id));
        dispatch(listMenuAction(getOrderReducer?.order?.branchID));
        dispatch(getReceiptViewAction(params.id))
        dispatch(listTablesAction(localStorage.getItem("branchID") !== "0" && `branch_id=${localStorage.getItem("branchID")}`));
    }, [dispatch, params.id, loading, trigger]);

    useEffect(() => {
        if (success) handleCloseModal()
    }, [success])

    useEffect(() => {
        const eventSource = new EventSource(`${BASE_URL}/orders/events`, {withCredentials: true});

        eventSource.addEventListener("OrdersUpdated", () => {
            setTimeout(() => {
                setTrigger((prev) => prev + 1); // SET TRIGGER AFTER 2 SECS TO WAIT FOR COMMIT
            }, 2000);
        });

        eventSource.addEventListener("connected", (event) => {
            console.log("Connected to SSE:", event.data);
        });

        // Cleanup on component unmount
        return () => {
            eventSource.close();
        };
    }, []);

    useEffect(() => {
        if (getOrderReducer) {
            setSelectedTable(getOrderReducer.order.tableID);
            setStatus(getOrderReducer.order.statusName);
            const initialStatuses = {};
            getOrderReducer.items.forEach(item => {
                initialStatuses[item.orderItemID] = item.statusName
            });
            setItemStatuses(initialStatuses);
        }
    }, [getOrderReducer]);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleNewItemClick = () => {
        setIsModalOpen(true);
    };

    const handleOpenReceipt = () => {
        setIsReceiptModalOpen(true);
    }

    const handleCloseReceipt = () => {
        setIsReceiptModalOpen(false);
    }

    const handleUpdateItemClick = (item) => {
        setSelectedItem(item);
        setIsUpdateModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsUpdateModalOpen(false);
        setSelectedItem(null);
    };

    const handleTableChange = (event) => {
        const selectedTableID = event.target.value;
        setPendingUpdate({tableID: selectedTableID, status}); // Set pending update
        setIsConfirmationOpen(true); // Open confirmation modal
        setUpdateTable(true)
    };

    const handleStatusChange = (event) => {
        setPendingUpdate({tableID: selectedTable, status: event.target.value}); // Set pending update
        setIsConfirmationOpen(true); // Open confirmation modal
        setUpdateStatus(true)
    };

    const handleOrderItemStatusChange = (event, itemID) => {
        const updatedStatus = event.target.value;
        setOrderItemPendingUpdate({orderItemID: itemID, orderItemStatus: updatedStatus});
        setIsConfirmationOpen(true);
    };


    const handleUpdateOrder = () => {
        if (pendingUpdate) {
            const body = {
                tableID: updateTable && pendingUpdate.tableID ? parseInt(pendingUpdate.tableID) : undefined,
                status: updateStatus && pendingUpdate.status,
            };
            dispatch(updateOrderAction(params.id, body));
            setPendingUpdate(null);
        }
        if (orderItemPendingUpdate) {
            dispatch(updateOrderAction(params.id, {
                    orderItemStatus: orderItemPendingUpdate.orderItemStatus,
                    orderItemID: orderItemPendingUpdate.orderItemID
                }
            ));
            setOrderItemPendingUpdate(null);
        }
        setUpdateStatus(false)
        setUpdateTable(false)
        setIsConfirmationOpen(false);
    };

    useEffect(() => {
        if (success) {
            setIsModalOpen(false);
        }
    }, [success]);

    const handleCancelUpdate = () => {
        setPendingUpdate(null);
        setOrderItemPendingUpdate(null);
        setUpdateStatus(false)
        setUpdateTable(false)
        setIsConfirmationOpen(false);
    };

    return (
        <div className="flex w-full bg-[#FFF7E1] min-h-screen">
            <Sidebar/>
            <div className="ml-80 w-full flex-1 mx-auto bg-[#FFF7E1] shadow-md rounded-lg">
                <header className="p-4 w-full border-gray-300">
                    <h1 className="text-5xl my-4 font-bold text-[#29333d]">Order
                        #{getOrderReducer?.order.orderID}</h1>
                    <hr/>
                    <div className="mt-4 w-full justify-between flex">
                        {getOrderReducer?.order?.typeName == 'dine-in' &&
                            <div>
                                <p className="text-2xl font-semibold text-[#40b06d]">Table #</p>
                                <select
                                    className="text-xl text-[#151e46] bg-transparent border border-[#40b06d] rounded-md mt-2"
                                    value={selectedTable}
                                    onChange={handleTableChange}
                                >
                                    {tableList?.map((table) => (
                                        <option key={table.id} value={table.id}>
                                            {table.tableNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div>
                            <p className="text-2xl font-semibold text-[#40b06d]">Status</p>
                            <select
                                className="text-xl text-[#151e46] bg-transparent border border-[#40b06d] rounded-md mt-2"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="in_progress">In Progress</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                        <div>
                            <p className="text-2xl font-semibold text-[#40b06d]">Time</p>
                            {getOrderReducer?.order &&
                                <>
                                    <p className="text-xl text-[#151e46]">{new Date(getOrderReducer?.order?.createdAt).toLocaleTimeString()}</p>
                                    <p className="text-xl text-[#151e46]">{new Date(getOrderReducer?.order?.createdAt).toLocaleDateString()}</p>
                                </>
                            }
                        </div>

                        <div>
                            <p className="text-2xl font-semibold text-[#40b06d]">Contact</p>
                            <p className="text-xl text-[#151e46]">{getOrderReducer?.order?.customerFirstName} {getOrderReducer?.order?.customerLastName}</p>
                            <p className="text-xl text-[#151e46]">{getOrderReducer?.order?.customerPhoneNumber}</p>
                        </div>

                        <div>
                            <p className="text-2xl font-semibold text-[#40b06d]">Type</p>
                            <p className="text-xl text-[#151e46]">{getOrderReducer?.order?.typeDisplayName}</p>
                        </div>
                    </div>
                    <button
                        className="mt-4 px-4 py-2 bg-[#40b06d] text-white font-semibold rounded-md"
                        onClick={handleNewItemClick}
                    >
                        New Item
                    </button>
                    <button
                        className={`mt-4 mx-4 px-4 py-2 font-semibold rounded-md ${
                            receipt == null ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#40b06d] text-white'
                        }`}
                        onClick={handleOpenReceipt}
                        disabled={receipt == null}
                    >
                        View Receipt
                    </button>

                </header>

                {getOrderReducer?.items?.map((item, index) => (
                    <div key={item.orderItemID}
                         onClick={() => toggleAccordion(index)} className="border-b w-full border-gray-300">

                        {/* Status and Edit Section Outside Accordion */}
                        <div className="p-4 flex justify-between items-center">
                            <div className="flex items-center space-x-2">
                                <p className="text-xl font-semibold text-[#29333d]">{item.quantity}x {item.name}</p>
                                <button
                                    onClick={() => handleUpdateItemClick(item)}
                                    className={`text-gray-600 hover:text-gray-800 ${item.statusName === 'cancelled' ? 'hidden' : ''}`}
                                >
                                    <FaEdit/>
                                </button>
                            </div>
                            {/* Status Dropdown */}
                            <div className="flex items-center space-x-4">
                                <p
                                    className={`text-xl font-semibold ${
                                        itemStatuses[item.orderItemID] === 'cancelled'
                                            ? 'text-red-600 font-bold'
                                            : 'text-[#2bb75c]'}`
                                    }
                                >
                                    {item.total} egp
                                </p>
                                <select
                                    className={`text-lg font-semibold bg-transparent border border-[#40b06d] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#40b06d] max-w-xs ${
                                        itemStatuses[item.orderItemID] === 'cancelled' ? 'bg-red-100 text-red-600 cursor-not-allowed' : 'text-[#151e46]'
                                    }`}
                                    value={itemStatuses[item.orderItemID]}
                                    onChange={(e) => handleOrderItemStatusChange(e, item.orderItemID)}
                                    disabled={itemStatuses[item.orderItemID] === 'cancelled'}
                                >
                                    <option value="pending">Pending</option>
                                    <option value="cancelled">Cancelled</option>
                                    <option value="preparing">Preparing</option>
                                    <option value="served">Served</option>
                                </select>
                            </div>
                        </div>

                        {/* Accordion Section */}
                        <div
                            className="cursor-pointer"
                        >
                            {openIndex === index && (
                                <div className="px-2 pb-2 border-b border-gray-300 mt-2 text-[#6b7885]">

                                    {/* Answers Section */}
                                    <div className="mb-4 p-4 bg-[#f0e6d2] shadow-sm rounded-lg">
                                        <h3 className="font-bold text-2xl text-[#151e46] mb-2">Answers</h3>
                                        {item.answers && item.answers.map((answerSet, ansIndex) => (
                                            answerSet && answerSet.map((answer, innerIndex) => (
                                                <div key={`${ansIndex}-${innerIndex}`} className="mb-2">
                                                    <div className="flex items-start mb-">
                                                        <span
                                                            className="font-semibold text-xl text-[#151e46] mr-2">{answer.question}:</span>
                                                        <span className="text-xl text-[#6b7885]">
                                                          {answer?.answers?.map((item, index) => {
                                                              if (typeof item === 'string') {
                                                                  return <span key={index}>{item}</span>;
                                                              } else if (typeof item === 'object' && item.choiceName && item.quantity>0) {
                                                                  return (
                                                                      <span key={index}>
                                                                  {item.choiceName} (x{item.quantity})
                                                                </span>
                                                                  );
                                                              }
                                                              return null;
                                                          })?.filter(Boolean).reduce((prev, curr) => [prev, ', ', curr])}
                                                        </span>

                                                    </div>
                                                </div>
                                            ))
                                        ))}
                                    </div>

                                    {/* Special Requests Section */}
                                    {item.specialRequest &&
                                    <div className="mb-2 p-2 bg-[#f0e6d2] shadow-sm rounded-lg">
                                        <h3 className="font-bold text-2xl text-[#151e46] mb-2">Special Requests</h3>
                                        {item.specialRequest && (
                                            <div className="mb-1">
                                                <span className="text-[#6b7885]"> {item.specialRequest}</span>
                                            </div>
                                        )}
                                    </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {isModalOpen && <AddNewItemModal menuList={menuList} onClose={handleCloseModal}/>}
            {isUpdateModalOpen && selectedItem && (
                <UpdateItemModal
                    itemData={selectedItem}
                    menuList={menuList}
                    onClose={handleCloseModal}
                />
            )}
            {isReceiptModalOpen && (
                <ReceiptModal receipt={receipt} onClose={handleCloseReceipt}/>
            )}
            {isConfirmationOpen && (
                <ConfirmationModal
                    message="Are you sure you want to update the order?"
                    onConfirm={handleUpdateOrder}
                    onCancel={handleCancelUpdate}
                />
            )}
            {error && (
                <BottomCallout
                    message={error}
                    variant={"error"}
                />
            )}
        </div>
    );
};

export default OrderPage;
