import React, { useState, useEffect } from 'react';
import {updateMenuAction} from "../../../redux/menu/actions";
import {useDispatch} from "react-redux";
import { MdDelete } from "react-icons/md";
import {useHistory, useLocation} from "react-router-dom";

const CustomizationOption = ({
                                 id,
                                 name,
                                 isRequired,
                                 isQuantity,
                                 maxNumberOfChoices,
                                 choices,
                                 handleAddChoice,
                                 handleRemoveChoice,
                                 toggleAccordion,
                                 isOpen,
                                 handleFieldChange,
                                 handleSave,
                                 handleDelete
                             }) => (
    <div className="mb-4 w-full p-2">
        <div
            className="bg-[#40b06d] border-2 rounded-lg text-white w-full p-2 flex justify-between items-center cursor-pointer"
            onClick={() => toggleAccordion(id)}
        >
            <span>{name}</span>
            <button className="text-lg font-bold">{isOpen ? '⮝' : '⮟'}</button>
        </div>
        {isOpen && (
            <div className="p-4">
                <div className="mb-2">
                    <div className="flex items-center justify-between my-2">
                        <label className="text-lg text-[#151e46] font-bold">Question</label>
                        <MenuDeleteButton handleDelete={handleDelete} id={id}/>
                    </div>

                    <input
                        type="text"
                        className="border border-gray-300 rounded-md w-full px-2 py-1"
                        value={name}
                        onChange={(e) => handleFieldChange(id, 'name', e.target.value)}
                    />
                </div>
                <div className="mb-2 flex items-center">
                    <label className="block font-bold text-lg text-[#151e46] mr-2">Required?</label>
                    <input
                        type="checkbox"
                        checked={isRequired}
                        onChange={(e) => handleFieldChange(id, 'isRequired', e.target.checked)}
                    />
                </div>
                <div className="mb-2 flex items-center">
                    <label className="block font-bold text-lg text-[#151e46] mr-2">Quantity Question</label>
                    <input
                        type="checkbox"
                        checked={isQuantity}
                        onChange={(e) => handleFieldChange(id, 'isQuantity', e.target.checked)}
                    />
                </div>
                <div className="mb-2">
                    <label className="block text-lg text-[#151e46] font-bold">Max Choices</label>
                    <input
                        type="number"
                        className="border border-gray-300 rounded-md w-full px-2 py-1"
                        min="1"
                        defaultValue={maxNumberOfChoices}
                        // value={maxNumberOfChoices}
                        onChange={(e) => handleFieldChange(id, 'maxNumberOfChoices',  e.target.value !== "" ? e.target.value :1 )}
                    />
                </div>
                <div>
                    <label className="block text-lg text-[#151e46] font-bold mb-2">Choices</label>
                    <div className="h-32 mb-2 overflow-y-auto">
                        {choices.map((choice, index) => (
                            <div key={index} className="flex items-center mb-2">
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded-md px-2 py-1 flex-grow mr-2"
                                    placeholder='Choice'
                                    value={choice.answer}
                                    onChange={(e) => handleFieldChange(id, `choices[${index}].answer`, e.target.value)}
                                />
                                <div className="flex items-center">
                                    <input
                                        type="number"
                                        className="border border-gray-300 rounded-l-md px-2 py-1 w-20"
                                        placeholder="0"
                                        min="0"
                                        required={true}
                                        value={choice.price}
                                        onChange={(e) =>
                                            handleFieldChange(id, `choices[${index}].price`, e.target.value !== "" ? e.target.value : 0)
                                        }
                                    />
                                    <span
                                        className=" text-gray-700 px-2 py-1 rounded-r-md border border-gray-300">
                                    EGP
                                  </span>
                                </div>

                                <button
                                    onClick={() => handleRemoveChoice(id, index)}
                                    className="text-red-500 font-bold"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-between">
                        <button
                            onClick={() => handleAddChoice(id)}
                            className="text-[#40b06d] border-[#40b06d] border-2 rounded-md p-2 flex items-center"
                        >
                            <span className="mr-1">Add new</span>+
                        </button>

                        <button
                            onClick={() => handleSave(true)}
                            className="text-[#fff7e1] border-[#40b06d] bg-[#40b06d] border-2 rounded-md p-2 flex items-center"
                        >
                            <span className="mr-1">Save</span>
                        </button>
                    </div>


                </div>
            </div>
        )}
    </div>
);

const CustomizationOptions = ({
                                  data,
                                  customizationID,
                                  setCustomizationID,
                                  customizationOption,
                                  setCustomizationOption,
                                  item,
                                  categoryName
                              }) => {
    const [options, setOptions] = useState(data || []);
    const [name, setName] = useState('');
    const [isRequired, setIsRequired] = useState(false);
    const [isQuantity, setIsQuantity] = useState(false);
    const [maxNumberOfChoices, setMaxNumberOfChoices] = useState(1);
    const [choices, setChoices] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingID, setPendingID] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false)


    const dispatch = useDispatch()

    useEffect(() => {
        if (customizationID) {

            const currentOption = data?.find((option) => option.id === customizationID);
            if (currentOption) {
                setName(currentOption.name);
                setIsRequired(currentOption.is_required);
                setIsQuantity(currentOption.is_quantity);
                setMaxNumberOfChoices(currentOption.max_number_of_choices);
                setChoices(currentOption.choices);
                setCustomizationOption({ ...customizationOption, name: currentOption.name, isRequired: currentOption.is_required, isQuantity: currentOption.is_quantity,
                    maxNumberOfChoices: currentOption.max_number_of_choices, choices: currentOption.choices });
            }
        }
    }, [customizationID,data]);

    const handleAddChoice = (id) => {
        setOptions(
            options.map((option) =>
                option.id === id
                    ? {
                        ...option,
                        choices: [...option.choices, { answer: '', price: 0 }],
                    }
                    : option
            )
        );
        if (customizationID === id) {
            setChoices([...choices, { answer: '', price: 0 }]);
        }
    };

    const handleNewOption = (e) => {
        e.preventDefault();
        const frontendID = Date.now().toString();
        const newOption = { isNew: true, id: frontendID, name: "", is_required: false, max_number_of_choices: 0, choices: [], isOpen: true };
        setOptions([...options.map(option => ({ ...option, isOpen: false })), newOption]);
        setCustomizationID(frontendID);
        setChoices([])
    };


    const handleRemoveChoice = (optionId, choiceIndex) => {
        setOptions(
            options.map((option) =>
                option.id === optionId
                    ? {
                        ...option,
                        choices: option.choices.filter((_, index) => index !== choiceIndex),
                    }
                    : option
            )
        );
        if (customizationID === optionId) {
            setChoices(choices.filter((_, index) => index !== choiceIndex));
            setCustomizationOption({ ...customizationOption, choices: choices.filter((_, index) => index !== choiceIndex) });
        }
    };

    const toggleAccordion = (id) => {
        if (customizationID && customizationID !== id && unsavedChanges) {
            setPendingID(id);
            setIsModalOpen(true);
        } else {
            setOptions(
                options?.map((option) => ({
                    ...option,
                    isOpen: option.id === id ? !option.isOpen : false,
                }))
            );
            setCustomizationID(id);
            const selectedOption = options.find(option => option.id === id);
            if (selectedOption) {
                setChoices(selectedOption.choices || []);
                setMaxNumberOfChoices(selectedOption.max_number_of_choices || 0);
            }

        }
    };

    const handleFieldChange = (id, field, value) => {
        setUnsavedChanges(true)
        setOptions(
            options.map((option) =>
                option.id === id
                    ? {
                        ...option,
                        [field]: value,
                    }
                    : option
            )
        );
        if (customizationID === id) {
            switch (field) {
                case 'name':
                    setName(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                case 'isRequired':
                    setIsRequired(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                case 'isQuantity':
                    setIsQuantity(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                case 'maxNumberOfChoices':
                    setMaxNumberOfChoices(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                default:
                    if (field.startsWith('choices[')) {
                        const index = parseInt(field.match(/choices\[(\d+)\]/)[1]);
                        const key = field.split('].')[1];
                        const newChoices = choices.map((choice, i) => {
                            if (i === index) {
                                return {
                                    ...choice,
                                    [key]: value,
                                };
                            }
                            return choice;
                        });
                        setChoices(newChoices);
                        setCustomizationOption({ ...customizationOption, choices: newChoices });
                    }
                    break;
            }
        }
    };

    const handleModalConfirm = (saveChanges) => {
        if (saveChanges) {
            const updateParams = {
                name: categoryName,
                branchID: parseInt(localStorage.getItem("branchID")),
                item: {
                    customization: customizationOption,
                    customizationID : typeof customizationID !== 'number' ? undefined:customizationID
                },
                itemID: item.id,
            };

            dispatch(updateMenuAction(item.category_id, updateParams, false,true));
        }
        setOptions(
            options.map((option) => ({
                ...option,
                isOpen: option.id === pendingID ? !option.isOpen : false,
            }))
        );
        setCustomizationID(pendingID);
        setUnsavedChanges(false)
        setPendingID(null);
        setIsModalOpen(false);
    };
    const handleDelete = (id) => {
        const updateParams = {
            name: categoryName,
            branchID: parseInt(localStorage.getItem("branchID")),
            item: {
                customizationID: id,
                customization: { delete: true},
            },
            itemID: item.id,
        };

        dispatch(updateMenuAction(item.category_id, updateParams, false, true));
        setOptions(options.filter((option) => option.id !== id));
    };
    return (
        <div className="max-w-full mx-auto bg-beige py-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl text-[#151e46] font-bold">Customizations</h2>
                <button onClick={handleNewOption} className="bg-[#40b06d] text-white rounded-full px-4 py-2">Add New +</button>
            </div>
            <div className="flex flex-wrap -mx-2">
                {options?.map((option) => (
                    <div key={option.id} className="w-full md:w-1/2 l:w-1/2 xl:w-1/2 py-2">
                        <CustomizationOption
                            {...option}
                            isRequired={isRequired}
                            IsQuantity={isQuantity}
                            maxNumberOfChoices={maxNumberOfChoices}
                            handleAddChoice={handleAddChoice}
                            handleRemoveChoice={handleRemoveChoice}
                            toggleAccordion={toggleAccordion}
                            isOpen={option.isOpen}
                            choices={choices}
                            handleFieldChange={handleFieldChange}
                            handleSave={handleModalConfirm}
                            handleDelete={handleDelete}
                        />
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-[#FFF7E1] p-4 rounded-lg shadow-lg">
                        <h3 className="text-lg font-bold mb-4">Unsaved Changes</h3>
                        <p className="mb-4">You have unsaved changes. Do you want to save or discard them before editing another option?</p>
                        <div className="flex justify-end">
                            <button onClick={() => handleModalConfirm(false)} className="bg-[#F0532F] text-white px-4 py-2 rounded-lg mr-2">Discard</button>
                            <button onClick={() => handleModalConfirm(true)} className="bg-[#40b06d] text-white px-4 py-2 rounded-lg">Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const MenuDeleteButton = ({ id, handleDelete }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const confirmDelete = () => {
        handleDelete(id);
        setModalOpen(false);
    };

    return (
        <>
            {/* Delete Button */}
            <button
                onClick={() => setModalOpen(true)}
                className="text-red-500 border-red-500 border-2 rounded-md p-2 flex items-center"
            >
                <MdDelete className="mx-1" />
            </button>

            {/* Confirmation Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-[#FFF7E1] p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                        <p className="text-gray-700 mb-6">
                            Are you sure you want to delete this item? This action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setModalOpen(false)}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmDelete}
                                className="bg-[#F0532F] hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


export default CustomizationOptions;
