import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BottomCallout from '../../../components/BottomCallout';
import {updateMenuAction, addMenuItemAction, listMenuAction} from '../../../redux/menu/actions';

const NewItemModal = ({ isOpen, onClose, categoryNames }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategory, setNewCategory] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [opensAt, setOpensAt] = useState(null);
    const [closesAt, setClosesAt] = useState(null);


    const dispatch = useDispatch();
    const menuUpdate = useSelector((state) => state.menuUpdateReducer);
    const menuAdd = useSelector((state) => state.menuAddReducer);
    const { loading, error, success } = menuUpdate;
    const { loading : loading2, error: error2, success:success2 } = menuAdd;
    const [submit, setSubmit] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();
        let params
        if(newCategory) {
            params = {
                name: newCategoryName,
                items: [{
                    name,
                    description,
                    price,
                    opensAt,
                    closesAt
                }],
            };
        } else {
             params = {
                name: categoryName,
                item: {
                    name,
                    description,
                    price,
                    opensAt,
                    closesAt
                },
            };
        }

        if (newCategory) {
            dispatch(addMenuItemAction(params, imageFile));
        } else {
            const categoryId = categoryNames.find(category => category.name === categoryName)?.id;
            dispatch(updateMenuAction(categoryId, params, imageFile));
        }

        setSubmit(true)
    };

    useEffect(() => {
        if (!submit) return;

        if ((!error && success &&!newCategory) || (!error2 && success2 && newCategory)) {
            // Dispatch list menu after successful addition
            dispatch(listMenuAction());
            onClose(); // Close the modal after the item is added
        }
    }, [error, error2, success, success2, submit, dispatch, onClose]);

    return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-[#fff7e1] p-6 rounded-lg w-full max-w-lg">
                    <h2 className="text-2xl font-bold mb-4 text-[#151e46]">Add New Item</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Category Name *</label>
                            <select
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={categoryName}
                                onChange={(e) => {
                                    setCategoryName(e.target.value);
                                    if (e.target.value === 'secretToken') {
                                        setNewCategory(true);
                                    } else {
                                        setNewCategory(false);
                                    }
                                }}
                                required
                            >
                                <option value="" disabled>Select a category</option>
                                {categoryNames.map((category) => (
                                    <option key={category.id} value={category.name}>{category.name}</option>
                                ))}
                                <option value="secretToken">Add New Category</option>
                            </select>
                            {newCategory && (
                                <input
                                    type="text"
                                    placeholder="New Category Name"
                                    className="block w-full mt-2 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                    value={newCategoryName}
                                    onChange={(e) => {
                                        setNewCategoryName(e.target.value);
                                        setNewCategory(true);
                                    }}
                                    required
                                />
                            )}
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Item Name *</label>
                            <input
                                type="text"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Description *</label>
                            <textarea
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Price *</label>
                            <input
                                type="number"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={price}
                                min="0"
                                step={"0.01"}
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Starting Time</label>
                            <input
                                type="time"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={opensAt}
                                onChange={(e) => setOpensAt(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Closing Time</label>
                            <input
                                type="time"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={closesAt}
                                onChange={(e) => setClosesAt(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Image *</label>
                            <input
                                type="file"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                onChange={(e) => setImageFile(e.target.files[0])}
                                required
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="mr-2 bg-[#F0532F] text-white px-4 py-2 rounded-md"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-[#40b06d] text-white px-4 py-2 rounded-md"
                                disabled={loading}
                            >
                                {loading || loading2 ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </form>
                    { (error || error2) && <BottomCallout message={error} variant="error" />}
                    { (success || success2) && <BottomCallout message="Item added successfully!" variant="success" />}
                </div>
            </div>

    );
};

export default NewItemModal;
